@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";


body {
  margin: 0;
  padding: 0;
  color: #002d62;
  background-color: #fff;
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.NavBar {
  width: 100%;
  height: 140px;
  min-height: 100px;
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
}

.logo-container {
  position: absolute;
  left: 70px;
}

.logo-image {
  height: 130px;
  width: 130px;
  margin: 5px;
}

.navigation-container {
  position: absolute;
  align-content: center;
  top: 45px;
  left: 360px;
}

.link {
  font-size: 25px;
  color: #002d62;
  text-decoration: none;
  margin-left: 30px;
}

.serach-container {
  background-color: #de851f;
}

.home-container {
  width: 100%;
}

.home-banner {
  background-color: #fdeedc;
  width: 100%;
  height: 70%;
  padding-bottom: 50px;
}

.title {
  font-size: 60px;
  font-weight: 700;
  color: #002d62;
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;
}

.city-title {
  color: #de851f;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

.banner-container {
  display: flex;
  padding-left: 120px;
  margin: 60px;
  align-items: center;
}

.sub-title {
  font-size: 8em;
  color: #000;
  color: rgba(0, 0, 0, 0.3);
  font-family: "Baumans", cursive;
  writing-mode: vertical-lr;
  direction: rtl;
  text-align: center;
}

.banner-image {
  width: 900px;
  height: 600px;
}

.start {
  width: 30px;
  height: 30px;
}

.start-image-container-1 {
  width: 40px;
  height: 30px;
  position: absolute;
  right: 360px;
  padding: 10px;
}

.start-image-container-2 {
  width: 40px;
  height: 30px;
  position: absolute;
  left: 360px;
  padding: 10px;
}

.carousel-inner {
  width: 50%;
  margin-top: 40px;
}

.carousel-inner > .carousel-item > img {
  height: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.carousel .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.container {
  padding: 40px;
}

.discover-wrapper {
  display: flex;
  width: 100%;
  padding: 10% 20%;
}

.options {
  height: 320px;
  width: 450px;
}

.search-container {
  display: flex;
  color: #ddd;
  width: 200px;
  height: 40px;
  border-radius: 10%;
  position: absolute;
  right: 40px;
  top: 40px;
  justify-content: space-around;
  padding: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.flex {
  display: flex;
}

.japan-1 {
  width: 600px;
  height: 300px;
  position: relative;
  margin: 7px;
}

.japan-2 {
  width: 600px;
  height: 300px;
  position: relative;
  margin: 7px;
}

.japan-3 {
  width: 600px;
  height: 300px;
  position: relative;
  margin: 7px;
}

.japan-4 {
  height: 300px;
  position: relative;
  margin: 7px;
}

.japan-5 {
  width: 76px;
  height: 300px;
  position: relative;
  margin: 7px;
}

.japan-6 {
  width: 600px;
  height: 300px;
  position: relative;
  margin: 7px;
}
.grey-area {
  background-color: #aea8a8;
  height: 300px;
  display: flex;
  color: #524e4e;
}

.sub-conatiner-area {
  width: 400px;
  padding: 40px;
  margin: 40px;
}
.footer {
  background-color: #002d62;
  color: #fff;
  display: flex;
}

.footer-wrapper {
  text-align: center;
  width: 70%;
  padding-top: 40px;
}

.links {
  color: #002d62;
  text-decoration: none;
  font-size: 20px;
}

.logo-footer {
  margin: 10px;
}

.icons-container {
  padding: 10px;
}

.emailIcon .facebookIcon .linkedinIcon .TwitterIcon {
  margin: 10px;
  padding: 30px;
}
